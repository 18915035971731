.hero {
    background: url("../img/Hero_AnimatedGIF.gif") no-repeat center;
    background-size: cover;
    position: relative;

    &__content {
        min-height: 46rem;
        height: 100%;

        & img {
            max-width: 667px;
            min-height: 189px;
            width: 100%;

            @media (max-width: 557px) {
                & {
                    max-width: 347.5px;
                    min-height: 93px;
                    margin-top: 26.46px !important;
                }
            }
        }
    }

    &:before {
        position: absolute;
        width: 100%;
        height: 70px;
        content: "";
        background: rgb(255, 255, 255);
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );
    }
}

.jumbo {
    padding: 3em 5em;
    border-radius: 21px;
    position: absolute;
    bottom: 8.7rem;
    left: 0;
    right: 0;

    @media (min-width: 1005px) {
        & {
            max-width: 1004px;
        }
    }

    @media (min-width: 1120px) {
        & {
            max-width: 1114px;
        }
    }
    // min-height: 181px;
    background-color: rgba(255, 255, 255, 0.95);

    p {
        line-height: 34px;
    }
}

@media only screen and (max-width: 557px) {
    .hero {
        &__content {
            min-height: 461px;
        }
    }

    .jumbo {
        padding: 2.5em 2em;
        background-color: $white;
        border-radius: 0;
        position: absolute;
        bottom: -40px;

        p {
            max-width: 304px;
            min-height: 100px;
            font-size: 14px;
            margin: 0 auto;
            line-height: 22px !important;
        }
    }
}

.videoArea {
    h2 {
        max-width: 785px;
        margin: auto;
        line-height: 40px;
        padding-left: 15px;
        padding-right: 15px;
    }

    @media only screen and (max-width: 557px) {
        h2 {
            max-width: 360px;
            font-size: 16px;
            line-height: 22px;
        }

        .iconPlay {
            width: 120px;
        }
    }
}

// Call Sec

.call-sec {
    background: url("../img/bg_call.png") no-repeat center;
    background-size: cover;
    background-color: #2a3b7c;

    @media (min-width: 1200px) {
        .container-xl {
            max-width: 100%;
            padding-left: 104px;
        }
    }

     
    @media only screen and (min-width: 767px) {
        .desktop-img-container {
            // height: 416px;
            // width: 665.68px;
            // width: 665px; 
           
            img {
                object-fit: cover;
            }
        }
    }

    &__icon-wrapper {
        @media (max-width: 557px) {
            & {
                max-width: 291px;
                min-height: 88px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    h2 {
        max-width: 434px;
        line-height: 40px;

        @media only screen and (max-width: 557px) {
            font-size: 16px !important;
            line-height: 24px;
        }
    }

    &__img {
        width: 138px;

        @media (min-width: 557px) {
            & {
                height: 178px;
            }
        }

        @media only screen and (max-width: 557px) {
            height: 88px;
            width: 69px;
        }
    }
}

// Carousel
/* carousel-height-control */
.carousel-section {
    min-height: 800px;

    @media only screen and (max-width: 557px) {
        & {
            min-height: 525px;
        }
    }
}

.bio-slider .carousel-inner {
    min-height: 41rem;
    max-width: 1154px;
    margin: 0 auto;
    padding-left: 12px;
    padding-right: 12px;

    @media (min-width:557px) and (max-width:767px) {
        & {
            min-height: 45rem;
        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        display: none !important;
    }

    img {
        @media (min-width: 991px) {
            & {
                max-width: 975px;
                min-height: 122px;
            }
        }
    }
    .eng-first-slide {
        @media (min-width: 991px) {
            & { 
                min-height: unset;
            }
        }
    }
}

.carousel-control-prev,
.carousel-control-next {
    display: none !important;
}

.fr .bio-slider .carousel-inner {
    min-height: 48rem;

    .carousel-control-prev,
    .carousel-control-next {
        display: none !important;
    }

    img {
        @media (min-width: 991px) {
            & {
                max-width: 975px;
            }
        }
    }

    .fr-carousel-item-img-1 {
        @media (min-width: 991px) {
            & {
                min-height: auto !important;
            }
        }
    }
}

@media only screen and (max-width: 557px) {
    .bio-slider .carousel-inner {
        min-height: 525px;
        padding: 2rem;
    }
}

.bio-slider .carousel-item {
    height: 100%;
}

.bio-slider .carousel-caption {
    position: initial;
    padding-top: 4.25rem;
    // bottom: unset;
    // left: 6%; right: 6%;
}

.bio-slider .carousel-caption p {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 18px;
}

.bio-slider .carousel-indicators {
    align-items: center;
}

.bio-slider {
    .pause-play-btn {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        font-size: 0.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.6rem;
        border: 2px solid #223e7f;
        color: #223e7f;

        &:hover,
        &:focus {
            border-color: #000;
        }
    }
    
}

.bio-slider .carousel-indicators [data-bs-target] {
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border-radius: 50%;
    margin-right: 1rem;
    border: 2px solid #223E7F;
    opacity: 1;
   

    @media only screen and (max-width: 557px) {
        width: 10px;
        height: 10px;
    }
}

.bio-slider .carousel-indicators .active[data-bs-target] {
    background-color: #223E7F;
    border-color: #223e7f;
    opacity: 1;
    border-style: none;
}

.cta-call {
    background: url("../img/CTA_ONE_Background.jpg") no-repeat center;
    background-size: cover;
}

.cta-contact {
    background: url("../img/CTA_ONE_Background.jpg") no-repeat center;
    background-size: cover;
    min-height: 491px;

    @media (max-width: 991px) {
        & {
         background: url("../img/Tagline_Background@2x.jpg") no-repeat center;
        }
     }

    h2 {
        min-height: 120px;
        max-width: 644px;
        margin: 0 auto !important;
    }

    .cta-contact__btn {
        min-width: 138.34px;
        min-height: 60px;
        border-width: 16.2px;

        @media (min-width: 767px) {
            & {
                min-width: 234px;
                min-height: 88px;
                border-width: 4px;
            }
        }
    }
}

.fr .cta-contact {
    background: url("../img/UN_background.jpg") no-repeat center,
        url("../img/UN_Bg.jpg") no-repeat center;
        background-size: cover;
    @media (max-width: 600px) {
       & {
        background: url("../img/Tagline_Background@2x.jpg") no-repeat center;
       }
    }
}

.cta-contact {
    & .row {
        min-height: 30rem;
    }

    @media only screen and (max-width: 767px) {
        & .row {
            // min-height: 18rem;
            min-height: 455px;
            padding-top: 2rem;
            padding-bottom: 2rem;

            h2 {
                margin-bottom: 40px !important;
            }
        }

        &__btn {
            min-width: 170px !important;
            height: 55px !important;
            border-radius: 15px !important;
            font-size: 18px !important;
        }
    }
}

.ctaBlock {
    &__content {
        max-width: 880px;
        min-height: 48px;
        margin-top: 60px;
        margin-bottom: 60px;
    }
}

 

.bc-player-default_default.vjs-play-button-position-top-left .vjs-big-play-button {
    background: url('../img/Icon_Play.svg');
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    background-size: contain;

    @media (max-width:557px) {
        width: 100px;
        height: 100px;
    }
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before, .video-js .vjs-play-control .vjs-icon-placeholder:before, .vjs-icon-play:before {
    content: "";
}
