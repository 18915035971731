.videoArea {
    a {
      margin-top: 22px;
      display: inline-block;
    }
   }
   .video-container {
    max-width: 990.97px;
    
    
   }
  
   .vjs-poster { 
      background-repeat: no-repeat;
      background-size: cover !important; 
  } 
  
  
  
  .video {
      position: relative;
    }
    
    .videoWrapper {
      position: relative;
      width: 100%;
      height: 0;
      background-color: #000;
      overflow: hidden;
      @media only screen and (min-width:991px) {
        & {
          height: 600px;
        }
      }
    }
    
    .videoWrapper169 {
      padding-top: 56.25%;
    }
    
    .videoIframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      // z-index: 2;
      // opacity: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      overflow: hidden;
    }
    
    .videoWrapperActive .videoPoster {
      opacity: 0;
      height: 0;
      transition-delay: 0s, 0.8s;
    }
    
    .videoPoster {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      cursor: pointer;
      border: 0;
      outline: none;
      background-position: 50% 50%;
      background-size: 100% 100%;
      background-size: cover;
      text-indent: -999em;
      overflow: hidden;
      opacity: 1;
      transition: opacity 0.8s ease, height 0s ease;
      transition-delay: 0s, 0s;
    }
    
    .videoPoster:after {
      content: ""; 
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%); 
      width: 210px;
      height: 210px; 
      background: url(../img/Icon_Play.svg);
      background-repeat: no-repeat;
  
      @media (max-width:557px) {
        & {
  
          background-size: 70.42px 70.45px ; 
          background-position: center;
        }
      }
    }
    
    .home-video-close {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-100%);
      display: block;
      line-height: 0;
      display: inline-block;
      padding: 20px 10px;
      font-size: 3em;
      font-weight: 700;
      color: white;
      cursor: pointer;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      transition: ease-in-out 0.15s all;
    }
    
    .home-video-close.is-hidden {
      display: block;
      transform: translateY(0);
    }
    
    #js-pointer-detector {
      display: none;
    }
    .js-videoPoster {
      background:url(../img/video-poster.png);
      background-repeat: no-repeat;
      background-size: cover !important; 
    }
    
    @media (pointer: coarse) {
      #js-pointer-detector {
        display: block;
      }
    
      // .js-videoPoster {
      //   display: none;
      // }
    }
     
  
    .videoPlay {
      opacity: 1 !important;
    }