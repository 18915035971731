
// 404 Page

.error-404 {
    h1 {
      font-size: 180px;
    }
  
    .btn {
      max-width: 180px;
      height: 45px;
    }

    @media (max-width:557px) {
        h1 {
            font-size: 100px;
          }
    }
  }
  