.contactus-banner {
    background-color: #012a53;

    // .row {
    //     @media only screen and (min-width:991px) {
    //         & {
    //             height: 736px;
    //         }
    //     }
    // }

    // &--fr {
    //     .row {
    //         @media only screen and (min-width:991px) {
    //             & {
    //                 height: unset;
    //             }
    //         }
    //     }
    // }
    

    &__left {
        background: url("../img/blurBg.jpeg") no-repeat center;
        background-size: cover;

        div {
            margin: auto;
            max-width: 512px;
            padding: 115px 0;

            h2 {
                font-size: 32px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 40px;
                margin-bottom: 55px;
            }

            p {
                font-size: 22px;
                line-height: 30px;
                margin-bottom: 25px;
            }
        }
    }

    &__right {
        background: url("../img/contactus-img.png") no-repeat center;
        background-size: cover;
    }

    &__cta {
        width: 100%;
        border-radius: 27px;
        height: 88px;
        margin-top: 55px;
        border: 2px solid $primary;
        font-size: 24px;
        line-height: 29px;

        .icon-hover {
            display: none;
        }

        &:hover,
        &:focus {
            background: linear-gradient(90deg, rgb(145, 186, 66) 0%, rgb(46 65 121 / 74%) 75%);

            .icon {
                display: none;
            }

            .icon-hover {
                display: initial;
            }

            border-color: $white !important;
            color: $white !important;
        }

        @media only screen and (max-width: 557px) {
            height: 65px;
            font-size: 16px;
        }

        @media only screen and (max-width: 425px) {
            font-size: 12px;

            img {
                width: 24px;
                margin-right: 1rem !important;
            }
        }
    }

    @media only screen and (max-width: 576px) {

        &__left {
            div {
                max-width: initial;
                padding: 57px 20px 42px 20px;
            }
        }
    }
}