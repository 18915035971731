
.btn-disabled {
    color: $white;
    opacity: 0.5;
    text-decoration: none;
    pointer-events: none;
    background-color: $prim-grey;
    border-color: $prim-grey;
  }
  
  .btn-primary {
    min-width: 250px;
    height: 85px;
    padding: 7px 15px;
    border-radius: 35px;
    border-width: 2px;
    background-color: $primary;
    color: $white;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.38px;
    text-align: center;
    text-decoration: none;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    &:active,
    &:hover,
    &:focus {
      background-position: 100% 0;
      transition: all 0.3s ease-in-out;
      color: $white;
      outline: none;
      text-decoration: none;
      background-color: $white ;
      color: $primary;
      @include box-shadow(1px 1px rgba($primary, 0.4));
    }
  }
  
  .btn-secondary,
  .btn-secondary.dropdown-toggle {
    min-width: 230px;
    height: 55px;
    padding: 7px 15px;
    border-width: 2px;
    border-radius: 15px;
    border-color: $primary;
    background: $primary;
    color: $white;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.38px;
    text-align: center;
    text-decoration: none;
    box-shadow: none;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    &:active,
    &:hover,
    &:focus {
      background-position: 100% 0;
      transition: all 0.3s ease-in-out;
      color: $white;
      outline: none;
      text-decoration: none;
      background-color: $white !important;
      border-color: $primary;
      @include box-shadow(1px 1px rgba($primary, 0.4));
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):focus {
      border-color: $primary;
      @include box-shadow(1px 1px rgba($primary, 0.4));
    }
  }
  