@for $f-size from $fs-max through $fs-min {
    .fs-#{$f-size} {
        font-size: #{$f-size}px;
    }

    @each $name, $breakpoint in $breakpoints {
        @media(min-width: $breakpoint) {
        .fs-#{$name}-#{$f-size} {
            font-size: #{$f-size}px !important;
        }
        }
    }
}

@for $l-size from $lh-max through $lh-min {
    .lh-#{$l-size} {
        line-height: #{$l-size}px;
    }

    @each $name, $breakpoint in $breakpoints {
        @media(min-width: $breakpoint) {
        .lh-#{$name}-#{$l-size} {
            line-height: #{$l-size}px !important;
        }
        }
    }
}

@for $top-position from 1 through 15 {
    .top-#{$top-position*10} {
        top: #{$top-position*10}#{'%'} !important;
    }
}

$colours: "red" $red, "blue" $prim-blue, "brown" $prim-brown, "grey" $prim-grey,
    "green" prim-green, "primary-beige" $prim-beige, "secondary-brown" $sec-brown,
    "secondary-grey" $sec-grey, "secondary-silver" $sec-dark-silver,
    "yellow" $yellow, "light-yellow" $light-yellow, "beige" $beige,
    "white" $white, "black" $black, "light-grey" $light-grey,
    "dark-grey" $dark-grey, "table-grey" $table-grey,
    "light-silver" $light-silver, "card-dark" $card-dark-blue,
    "card-light" $card-light-blue;
@each $i in $colours {
    .bg-#{nth($i, 1)} {
        background: nth($i, 2);
    }
    .text-#{nth($i, 1)} {
        color: nth($i, 2) !important;
    }
}
