// Montserrat Google Font
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Montserrat-100 - latin */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 100;
    src: url("../fonts/Montserrat-Thin.ttf"); /* IE9 Compat Modes */
}
@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 100;
    src: url("../fonts/Montserrat-ThinItalic.ttf"); /* IE9 Compat Modes */
}

/* Montserrat-200 - latin */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 200;
    src: url("../fonts/Montserrat-ExtraLight.ttf"); /* IE9 Compat Modes */
}
@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 200;
    src: url("../fonts/Montserrat-ExtraLightItalic.ttf"); /* IE9 Compat Modes */
}

/* Montserrat-300 - latin */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/Montserrat-Light.ttf"); /* IE9 Compat Modes */
}
@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 300;
    src: url("../fonts/Montserrat-LightItalic.ttf"); /* IE9 Compat Modes */
}

/* Montserrat-400 - latin */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Montserrat-Regular.ttf"); /* IE9 Compat Modes */
}

/* Montserrat-500 - latin */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/Montserrat-Medium.ttf"); /* IE9 Compat Modes */
}
@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 500;
    src: url("../fonts/Montserrat-MediumItalic.ttf"); /* IE9 Compat Modes */
}

/* Montserrat-600 - latin */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/Montserrat-SemiBold.ttf"); /* IE9 Compat Modes */
}
@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 600;
    src: url("../fonts/Montserrat-SemiBoldItalic.ttf"); /* IE9 Compat Modes */
}

/* Montserrat-700 - latin */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Montserrat-Bold.ttf"); /* IE9 Compat Modes */
}
@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 700;
    src: url("../fonts/Montserrat-BoldItalic.ttf"); /* IE9 Compat Modes */
}

/* Montserrat-800 - latin */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    src: url("../fonts/Montserrat-ExtraBold.ttf"); /* IE9 Compat Modes */
}
@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 800;
    src: url("../fonts/Montserrat-ExtraBoldItalic.ttf"); /* IE9 Compat Modes */
}

/* Montserrat-900 - latin */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/Montserrat-Black.ttf"); /* IE9 Compat Modes */
}
@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 900;
    src: url("../fonts/Montserrat-BlackItalic.ttf"); /* IE9 Compat Modes */
}
