// Color Variables

// Primary
$white: #fff !default;
$black: #000000 !default;
$primary: #2a3b7c !default;
$prim-blue: #0c2392 !default;
$prim-brown: #a06803 !default;
$prim-grey: #656565 !default;
$prim-green: #147664 !default;
$prim-beige: #e8c3ab !default;

// Secondary
$sec-brown: #200001 !default;
$sec-beige: #f2f4fa !default;
$sec-grey: #e6e7e8 !default;
$sec-dark-silver: #cdd0df !default;
$yellow: #edec2f !default;
$red: #df0404 !default;
$light-yellow: #f7f7d1 !default;

//body bg color
$beige: #f2f4fa !default;
$light-grey: #fbfbfb !default;
$dark-grey: #f7f7f8 !default;
$light-silver: #f5f6fa !default;
$body-color: $white !default;

//table border color
$table-grey: #d2d3e1 !default;

//card-large
$card-light-blue: #0a1f83 !default;
$card-dark-blue: #100e53 !default;

//body bg color
$body-color: $gray-100 !default;

//font family
$font-family: "Montserrat", sans-serif !default;

$animation-duration: 200ms;

//font size and line height values
$breakpoints: (
    sm: 576px,
    md: 768px,
    lg: 992px,
);

$fs-min: 12;
$fs-max: 50;

$lh-min: 18;
$lh-max: 42;
