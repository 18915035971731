.top-nav {

    margin: auto;
    height: 112px;
    max-width: 1440px;
    padding: 0 106px 0 81px;
    align-items: flex-start;
    justify-content: space-between;

    .navbar-brand {
        width: 235px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $sec-beige;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        margin-right: 0;

        img {
            width: 180px;
        }
    }

    .navbar-nav {

        gap: 15px;
        padding-top: 27px;

        .nav-link {
            color: #6D6D6D;
            width: 57px;
            height: 57px;
            font-size: 20px;
            line-height: 20px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
        }

        .nav-link.active {
            color: $white;
            background-color: $primary;
        }

        .nav-link:not(.active):hover {
            color: $primary !important;
            font-weight: 600;
            text-decoration: underline;
        }
    }

    @media only screen and (max-width: 576px) {

        height: 80px;
        padding: 0 20px 0 20px;

        .navbar-brand {
            width: 145px;
            height: 60px;

            img {
                width: 110px;
            }
        }

        .navbar-nav {

            gap: 8px;
            padding-top: 12px;

            .nav-link {
                width: 46px;
                height: 46px;
                font-size: 16px;
            }

            .nav-link.active {
                background-color: #F2F4FA;
                color: $primary;
                font-weight: bold;
            }
        }
    }
}
 