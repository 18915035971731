@import "imports.scss";
 

body {
    font-family: $font-family;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5em;
    background-color: $white;
    color: $black;
    height: 100%;
    overflow-x: hidden; 
}

html {
    position: relative;
    min-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family;
    font-weight: 600;
    line-height: 1.1em;
    margin-bottom: 20px;
}

p {
    font-size: 16px;
}

a.skip-main {
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: 1;
}

a.skip-main:focus,
a.skip-main:active {
    color: #fff;
    background-color: $primary;
    left: auto;
    top: auto;
    width: 30%;
    height: auto;
    overflow: auto;
    margin: 10px 35%;
    padding: 5px;
    border-radius: 15px;
    border: 4px solid $black;
    text-align: center;
    font-size: 1.2em;
    z-index: 1;
}

main {
    max-width: 1440px;
    margin: auto;
    overflow: hidden;
}

.content-wrapper {
    background-color: $white;
    width: 100%;
    overflow-x: hidden;
}

.scroll-container {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
}

.flex-basis-auto {
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-basis: auto;
    flex-basis: auto;
}

.bg-dark {
    background-color: $prim-beige !important;
}

.hide {
    display: none !important;
}

/* Navbar */

.navbar .navbar-toggler {
    top: 0.25rem;
    right: 1rem;
}

.navbar .form-control {
    padding: 0.75rem 1rem;
    border-width: 0;
    border-radius: 0;
}

.terms,
.privacy {
    margin: 53px auto 80px auto;
    max-width: 1227px;

    p,
    ol {
        font-size: 14px;
    }
 

    ol {
        padding-left: 2.5rem;

        li {
            &::marker {
                font-weight: 700 !important;
            }
        }
    }

    .outer-list {
        list-style: disc;
        li {
            &::marker {
                font-size: 8px;
                font-weight: 100 !important;
            }
        }
    }

    .list-12 {
        li {
            &::marker {
                font-size: 12px;
            }
        }
    }

    a {
        line-break: anywhere;
    }
}

.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

.cursor-pointer {
    cursor: pointer;
}

.loader-wrapper {
    display: flex;
}

// Custom Container

@media (min-width: 1200px) {
    .container-xl {
        max-width: 1240px;
    }
}

// List style
.ol-style {
    padding-left: 1.5rem;

    & li::marker {
        font-size: 1rem;
        font-weight: 700;
    }

    & li ul li {
        list-style: disc;
        padding-left: 1rem;

        &::marker {
            font-size: 0.7rem;
        }
    }
}

.list-style-none {
    list-style: none;
}

.list-style-disc {
    list-style: disc;
}

/* Link Hover Effect */

.hover-underline-animation {
    display: inline-block;
    position: relative;
}

.hover-underline-animation::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: -1px;
    left: 0;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation--primary::after {
    background-color: $primary;
}

.hover-underline-animation--white::after {
    background-color: $white;
}

.hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

/* Footer */

footer {
    margin: auto;
    max-width: 1440px;
    
    padding: 82px 20px 13px 20px;

    @media (min-width:1200px) {
        & {
            max-height: 452px;
        }
    }

    section {
        margin: auto;
        max-width: 1229px;
    }

    hr {
        margin-top: 38px;
        border: 1px solid #fff;
        opacity: 0.3;
        margin-bottom: 37px;
    }

    .footer__row {

        .footer__links {
            width: 100%;

            @media (min-width: 480px) {
                & {
                    min-width: 450px;
                }
            }
            margin-left: auto;
            min-height: 32px;
            gap: 30px;

            .nav-link {
                font-size: 16px;
                line-height: 16px;
                font-weight: 600;
                text-transform: uppercase;
                padding-bottom: 0;
            }
        }
    }

    .footer__content {
        padding-bottom: 78px;
        max-width: 703px; 
        // min-height: 160px;

        p {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 18px;
            font-weight: 300;

            &:last-child {
                margin-bottom: 0;
            }

            b {
                font-weight: 600;
            }
        }
    }

    .footer__logos {
        gap: 24px;
    }

    @media only screen and (max-width: 767px) {

        padding: 56px 20px 36px 20px;

        .footer__row {

            .footer__links {
                padding-top: 32px;
                gap: 15px;
            }
        }

        .footer__content {
            padding-bottom: 40px;
        }

        .footer__logo {
            img {
                height: 46.54px;
                width: 145.02px;
            }
        }

        .footer__logos {

            & img {
                display: block;
                width: max-content;
                
            }
        }
    }
}


@media only screen and (max-width: 1280px) {

    .terms,
    .privacy {
        padding: 0 20px 0;
    }

}

@media only screen and (max-width: 576px) {

    .terms,
    .privacy {
        margin: 30px auto 50px auto;
    }

}

.link-color {
    color: #2619e2 !important;
}
.link-color-2 {
    color: #1536ec !important;
}

.oneLine {
    white-space: nowrap;
}